import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '@common/Layout';
import { Container } from '@components/global';
import {
  ContentContainer,
  ContentMain,
  Row,
  TextBodyWrapper,
  StyledH1,
} from '../components/global';
import Footer from '@sections/Footer';
import Img from 'gatsby-image';

import Navbar from '@common/Navbar';

export default function LegalTemplate({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      {frontmatter.hero ? (
        <Navbar style={{ position: 'absolute' }} />
      ) : (
        <Navbar style={{ position: 'relative' }} />
      )}

      {frontmatter.hero ? (
        <Container style={{ position: 'relative' }}>
          <Hero fluid={frontmatter.hero.childImageSharp.fluid} />
          <TranslucentTextBox></TranslucentTextBox>
          <StyledText>
            <StyledParagraph>Unsere Leistungen</StyledParagraph>
            <StyledTitle>{frontmatter.title}</StyledTitle>
          </StyledText>
        </Container>
      ) : null}
      <ContentMain>
        <ContentContainer>
          <Row>
            {/* <Link to="/">Zurück</Link> */}
            <LegalTextBodyWrapper>
              <TextBodyWrapper>
                {frontmatter.hero ? null : (
                  <StyledH1>{frontmatter.title}</StyledH1>
                )}
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </TextBodyWrapper>
            </LegalTextBodyWrapper>
          </Row>
        </ContentContainer>
      </ContentMain>
      <Footer />
    </Layout>
  );
}
export const legalTemplateQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        hero {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const LegalTextBodyWrapper = styled.div`
  text-align: left;

  h2 {
    margin-top: 1em;
    margin-bottom: 1em;
    word-break: break-word;
    ${props => props.theme.font_size.larger};
  }
  h3 {
    margin-top: 1em;
    margin-bottom: 1em;
    word-break: break-word;
    ${props => props.theme.font_size.large};
  }
  h4 {
    margin-top: 1em;
    margin-bottom: 1em;

    ${props => props.theme.font_size.small};
    font-weight: 500;
  }
  p {
    ${props => props.theme.font_size.small};
    margin-bottom: 40px;
  }
  li {
    ${props => props.theme.font_size.small};
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    margin: 1em 1em 1em 1.5em;
    h1 {
      font-size: 26px;
    }
    h2 {
      text-align: center;
      ${props => props.theme.font_size.small};
    }
    h3 {
      margin-top: 1em;
      margin-bottom: 1em;
      word-break: break-word;
      ${props => props.theme.font_size.xsmall};
    }
    h4 {
      margin-top: 1em;
      margin-bottom: 1em;

      ${props => props.theme.font_size.xsmall};
    }
    p {
      ${props => props.theme.font_size.xsmall};
      margin-bottom: 40px;
    }
    li {
      ${props => props.theme.font_size.xsmall};
    }
  }
`;

const Hero = styled(Img)`
  width: 100%;
  height: 100vh;
  margin: 0;

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: auto;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    height: auto;
  }
`;
const TranslucentTextBox = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0px;
  position: absolute;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
`;
const StyledText = styled.div`
  width: 60%;
  position: absolute;
  bottom: 0px;
  text-align: left;
  transform: translate(15%, -200%);
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
  font-size: 32px;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 40%);
    text-align: center;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 40%);
    text-align: center;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    width: 100%;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 40%);
    text-align: center;
  }
`;
const StyledParagraph = styled.p`
  color: rgba(255, 255, 255, 1);
  font-weight: 100;
  font-size: 24px;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`;
const StyledTitle = styled.h3`
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
  font-size: 48px;
  margin-top: 0px;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 60px;
    line-height: 48px;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    font-size: 36px;
    line-height: 48px;
  }
`;
